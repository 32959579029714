<template>
  <v-content class="cbg">
    <v-container
      fill-height
      fluid
    >
      <v-layout
        align-center
        justify-center>
        <v-flex
          xs12
          sm6
          md4
          lg3

        >
          <v-card
            style="border-radius: 15px"
            class="elevation-8 py-8"
          >
          <v-row
            justify="center"
          >
            <div class="text-center">
              <v-img
                      src="@/assets/imgs/akoko_market_logo.png"
                      class="white--text align-center mx-auto"
                      height="55px"
                      width="180px"
              />
              <span class="grey--text caption text--darken-1 mr-0"><v-icon small>mdi-copyright</v-icon>Enterprise Edition</span>
            </div>
            <v-row justify="center" align="center" class="col-11 mt-12">
              <v-card-text>
<!--                <p class="display-2 text&#45;&#45;primary mb-3">Admin</p>-->
                <v-form>
<!--                  <v-text-field-->
<!--                          ref="phone"-->
<!--                          v-model="phone"-->
<!--                          :rules="[() => !!phone || 'This field is required']"-->
<!--                          filled-->
<!--                          prepend-inner-icon="mdi-phone"-->
<!--                          placeholder="Phone"-->
<!--                          required-->
<!--                          @keydown.enter="login"-->

<!--                  />-->
                  <akoko-phone
                      class=""
                      @update="userPhoneNo"
                      v-model="phone"
                      size="lg"
                      default-country-code="GH"
                  >
                  </akoko-phone>
<!--                  <v-text-field-->
<!--                          ref="password"-->
<!--                          v-model="password"-->
<!--                          :rules="[() => !!password || 'This field is required']"-->
<!--                          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"-->
<!--                          :type="showPassword ? 'text' : 'password'"-->
<!--                          filled-->
<!--                          prepend-inner-icon="mdi-lock"-->
<!--                          placeholder="Password"-->
<!--                          counter-->
<!--                          required-->
<!--                          @keydown.enter="login"-->
<!--                          @click:append="showPassword = !showPassword"-->
<!--                  />-->
                </v-form>
              </v-card-text>
              <v-card-actions class="col-9">
                <v-btn
                    id="sign_btn"
                    :loading="mloading"
                    align-center
                    justify-center
                    color="primary"
                    dark
                    large
                    block
                    rounded
                    @click="login"><v-icon
                    left
                    >mdi-lock-open-variant</v-icon> Login
                </v-btn>
              </v-card-actions>
              <div class="col-12 mt-3">
                <v-btn
                  text
                  color="secondary"
                  rounded
                  @click="$router.push({name: 'home'})"
                >
                  <v-icon>mdi-arrow-left</v-icon> Back
                </v-btn >
              </div>
            </v-row>
            </v-row>
          </v-card>
          <v-dialog
              style="border-radius:20px"
              v-model="phone_verification"
              width="300"
              persistent
          >
            <v-card style="border-radius:20px" flat>
              <v-toolbar dense flat>
                <v-btn icon @click.native="closeVerify">
                  <v-icon color="grey darken-1">mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-title class="subheading grey--text text--darken-2">
                <v-icon color="red" left>mdi-shield-check</v-icon>
                Verify Phone Number
              </v-card-title>
              <div class="px-6 text-center caption mx-2 my-4">
                We have sent an SMS with a code to
                <i class="font-weight-bold">{{ phoneObj.e164 }}</i
                >, please Enter the 6-digit activation code to complete signin.
              </div>

              <v-card-text class="mb-n6">
                <v-form>
                  <v-text-field
                      dense
                      rounded
                      outlined
                      v-model="vcode"
                      prepend-inner-icon="mdi-message-processing"
                      placeholder="6-digit code"
                      required
                  />
                </v-form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn small color="grey" text @click="login">
                  <v-icon>mdi-refresh</v-icon>
                  Re-Send Code
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" :disabled="loading" rounded color="primary" @click="checkCode">
                  Verify
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-layout
              row v-if="error">
            <v-flex xs12 sm12>
              <app-alert
                  @dismissed="onDismissed"
                  value="true"
                  text="errormessage"
              ></app-alert>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {
  data: function () {
    return {
      phone: '',
      password: '',
      showPassword: false,
      loader: false,
      mloading: false,
      phoneObj: {},
      phone_verification: false,
      vcode: "",
      google_loading: false,
      facebook_loading: false
    }
  },

  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/dash/book");
      }
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    signin_form() {
      return this.$store.getters.showLogin;
    },
    signup_form() {
      return this.$store.getters.showSignup;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    }
  },

  mounted() {
    this.firebase.auth().useDeviceLanguage();
    window.recaptchaVerifier = new this.firebase.auth.RecaptchaVerifier("sign_btn", {
      size: "invisible",
      callback: function(response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        //onSignInSubmit();
        console.log("recapture solved");
      }
    });
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    showLogin() {
      this.$store.commit("setLogin", true);
    },
    verifyPhone() {
      this.phone_verification = true;
    },
    closeVerify(){
      this.phone_verification = false;
      this.$store.commit('setLoading',false);
    },
    goHome() {
      this.$router.push("/");
    },
    goToAdmin(){
      this.$router.push("/admin")
    },
    checkCode() {
      let code = this.vcode;
      let self = this;
      this.$store.commit('setLoading',true);
      //console.log(code);
      confirmationResult
          .confirm(code)
          .then(function(result) {
            console.log("successfully login");
            // User signed in successfully.
            let user = result.user;
            self.$router.push('/admin')
          })
          .catch(function(error) {
            self.$store.commit('setLoading',false);
            self.$store.commit("setSnack", {
              color: "red",
              status_msg: "Error",
              added_msg:
                  "Verification Code is wrong. Please check and send again."
            });
            console.log(error);
          });
    },
    userPhoneNo(payload) {
      console.log(payload);
      this.phoneObj = payload;
    },
    getToken(){
      localStorage.removeItem('token');
      this.$store.dispatch('getToken')
          .then((response) =>{
            localStorage.setItem('token', response)
            console.log(response);
          })
          .catch(err => {
            self.loader = false;
            console.log(err)
          })
    },
    onDismissed() {
      console.log("dismissed");
      this.$store.dispatch("clearError");
    },
    login: function() {
      let self = this;
      if (this.phoneObj.isValid) {
        this.mloading = true
        this.$store.commit('setLoading',true);
        let phoneNumber = this.phoneObj.e164;
        let appVerifier = window.recaptchaVerifier;
        this.firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function(confirmationResult) {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              self.mloading = false
              window.confirmationResult = confirmationResult;
              console.log({ success: "good", confirmationResult });
              self.$store.commit('setLoading',false);
              self.phone_verification = true;
            })
            .catch(function(error) {
              self.mloading = false
              self.$store.commit("setSnack", {
                color: "red",
                status_msg: "Error",
                added_msg: "Network Error."
              })
            }).
        finally(_=>{
          this.$store.commit('setLoading',false);
        })

      } else {
        self.$store.commit("setSnack", {
          color: "red",
          status_msg: "Error",
          added_msg: "Phone number is invalid."
        });
        this.phone_verification = false;
        console.log("Phone No. is invalid.");
      }
    },
  },
  metaInfo () {
    return {
      title: 'Akokomarket | Login'
    }
  }
}
</script>

<style scoped lang="scss">
  .cbg{
    border:1px solid #e3e3e3;
    background: #e3e3e3;
    height: 100vh;
    /*background-image:url('../assets/img/akoko1.jpg');*/
  }
</style>
